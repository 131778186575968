<!-- 
   SPEED version 1.0
  
   Copyright ⓒ 2022 kt corp. All rights reserved.
   
   This is a proprietary software of kt corp, and you may not use this file except in 
   compliance with license agreement with kt corp. Any redistribution or use of this 
   software, with or without modification shall be strictly prohibited without prior written 
   approval of kt corp, and the copyright notice above does not evidence any actual or 
   intended publication of such software. 
 -->
<template>
  <div id="cfmCLContainer"> <!-- st.cfmCLContainer -->
    <div id="cfmClContents"> <!-- st.cfmClContents -->
      <div class="visual cscenter"> <!-- st.visual cscenter -->
        <div class="column"> <!-- st.column -->
          <div class="inner"> <!-- st.inner -->
            <div class="location"> <!-- st.location -->
                  <span>
                    <a class="home" :href="locationInfo.home">HOME</a>
                  </span>
                  <span>
                    <a :href="locationInfo.cust">고객지원</a>
                  </span>
                  <span>
                    <a :href="locationInfo.sla">품질확인 및 A/S 신청하기</a>
                  </span>
                  <span>
                    <a :href="locationInfo.speed">인터넷 속도측정</a>
                  </span>
                  <span title="현재위치">
                    <a href="#" @click="currentPageNavClick" v-if="currentPageName=='SpeedTestIntroduce'">속도테스트</a>
                    <a href="#" @click="currentPageNavClick" v-if="currentPageName=='SpeedHistory'">속도테스트 이력</a>
                    <a href="#" @click="currentPageNavClick" v-if="currentPageName=='SLATestIntroduce'">품질보증 테스트</a>
                    <a href="#" @click="currentPageNavClick" v-if="currentPageName=='SLAHistory'">품질보증 테스트 이력</a>
                    <a href="#" @click="currentPageNavClick" v-if="currentPageName=='SLAComplain'">품질보증 테스트 이의신청</a>
                    <a href="#" @click="currentPageNavClick" v-if="currentPageName=='Guide'">속도측정 길잡이</a>
                  </span>
            </div>
            <!-- end.location -->

            <div class="hgroup is-black">
              <h3 class="internet">인터넷 속도측정</h3>
              <p class="hdesc">느려진 인터넷 속도! 속도측정으로 해결해 보세요.</p>
            </div>

            <div id="csConts" class="csConts"> <!-- st.csConts -->
              <div class="csContsMajor"> <!-- st.csContsMajor -->
                <div class="csContsNav normal-space">
                  <div class="exMainNav">
                    <div class="mainNav">

                      <a href="/" @click="commonNaviClick($event, '#01')" :class="{ on : currentPageName=='SpeedTestIntroduce' }">속도테스트</a>
                      <a href="/" @click="commonNaviClick($event, '#02')" :class="{ on : currentPageName=='SpeedHistory' }">속도테스트 이력</a>
                      <a href="/" @click="commonNaviClick($event, '#03')" :class="{ on : currentPageName=='SLATestIntroduce' }">품질보증 테스트</a>
                      <a href="/" @click="commonNaviClick($event, '#04')" :class="{ on : currentPageName=='SLAHistory' }">품질보증 테스트 이력</a>
                      <a href="/" @click="commonNaviClick($event, '#05')" :class="{ on : currentPageName=='SLAComplain' }">품질보증 이의 신청</a>
                      <a href="/" @click="commonNaviClick($event, '#06')" :class="{ on : currentPageName=='Guide' }" >속도측정길잡이</a>
                      <!-- <a href="/" @click="commonNaviClick($event, '#06')" :class="{ on : currentPageName=='Guide' }" v-if="currentPageName!='Guide'">속도측정길잡이</a> -->
                      <div class="mainNav_havSub" style="position:static;margin-top:-6px;" v-if="currentPageName=='Guide'">
                        <div class="subNavBox" v-show="currentPageName=='Guide'">
                          <a href="#" class="subNav"
                            :class="{ on : currentGuideComponent=='CompSpeedTestGuide' }"
                            @click="guidePageButton($event, 'CompSpeedTestGuide')">속도 테스트 가이드</a>

                          <a href="#" class="subNav"
                            :class="{ on : currentGuideComponent=='CompSLATestGuide' }"
                            @click="guidePageButton($event, 'CompSLATestGuide')">SLA 테스트 가이드</a>

                          <a href="#" class="subNav"
                            :class="{ on : currentGuideComponent=='CompInstallGuide' }"
                            @click="guidePageButton($event, 'CompInstallGuide')" id="<%=guideId%>" >측정 프로그램 설치</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a class="prevNav" href="#"><span class="hide">이전메뉴이동</span></a>
                  <a class="nextNav" href="#"><span class="hide">다음메뉴이동</span></a>
                </div>
              </div> <!-- end.csContsMajor -->
              <!--  -->
              <router-view></router-view>
              <!--  -->
            </div> <!-- end.csConts -->

          </div><!-- end.inner -->
        </div><!-- end.column -->
      </div><!-- end.visual cscenter -->
    </div><!-- end.cfmClContents -->
  </div><!-- end.cfmCLContainer -->
</template>

<script>
/* eslint-disable */
export default {
  name : 'App',
  components: {},
  data() {
    return {
      IsOK : false,
      locationInfo : {
        home  : "",
        cust  : "",
        sla   : "",
        speed : ""
      }
    }
  },
  beforeCreate() {},
  async created() {
    for(var name in this.locationInfo ) {
      var path = "";
      switch(name) {
        case "home"  :
          path = this.$store.getters.scriptUrl.COM_KT_WWW + "/";
          break;
        case "cust"  :
          path = this.$store.getters.scriptUrl.COM_KT_HELP + "/main.jsp";
          break;
        case "sla"   :
          path = "speed.kt.com/" + this.$store.getters.scriptUrl.DRECTORY_NAME + "/speedtest/introduce.asp";
        case "speed" :
          path = "speed.kt.com/" + this.$store.getters.scriptUrl.DRECTORY_NAME + "/speedtest/introduce.asp";
          break;
        case "guide" :
          path = "speed.kt.com/guide/guide.asp";
          break;
        default : break;
      }
      this.locationInfo[name] = "https://" + path;
    }
  },
  mounted() {},
  beforeUpdate(){},
  updated() {},
  beforeDestroy(){},
  destroyed() {},
  methods: {
    currentPageNavClick(e) {
      e.preventDefault();
    },
    commonNaviClick(e, tabId) {
      e.preventDefault();
      //var url = "http://speed.kt.com/";
      var url = "";
      var pageName = "";
      var path = "";
      switch(tabId){
        case '#01': //url += 'speed/speedtest/introduce.asp';
          pageName = "SpeedTestIntroduce"; 
          path = "/speed/speedtest/introduce.asp";
          break;
        case '#02': //url += 'speed/history/history.asp';
          pageName = "SpeedHistory";
          path = "/speed/history/history.asp";
          break;
        case '#03': //url += 'sla/slatest/introduce.asp';
          pageName = "SLATestIntroduce";
          path = "/sla/slatest/introduce.asp";
          break;
        case '#04': // url += 'sla/history/history.asp';
          pageName = "SLAHistory";
          path = "/sla/history/history.asp";
          break;
        case '#05': //url += 'sla/history/comphistory.asp';
          pageName = "SLAComplain";
          path = "/sla/history/comphistory.asp";
          break;
        case '#06': //url += 'guide/guide.asp';
          pageName = "Guide";
          path = "/guide/guide.asp";
          break;
        default : break;
      }

      if(window.kt.isLogin()=='N' && (tabId=='#02' || tabId=='#04' || tabId=='#05')){
        //window.kt.popupLogin(url);
        window.kt.popupLogin();
        return false;
      } else {
        // if ( !!path ) {
        //   //this.$router.replace({name:pageName, params: {}}).catch((e)=>{});
        // } else {
        //   //this.$router.push({name:pageName, params: {}}).catch((e)=>{});
        // }
        window.location.href = path;
      }
      return false;
    },
    /**
     * 가이드 탭. // speedtestguide slaspeedtestguide install
     * @param {*} e 
     * @param {*} guideComponentName 
     */
    guidePageButton(e, guideComponentName) {
      e.preventDefault();
      switch(guideComponentName) {
        case "CompSpeedTestGuide" : break;
        case "CompSLATestGuide"   : break;
        case "CompInstallGuide"   : break;
        default : break;
      }
      this.$eventBus.$emit("setCurrentGuideComponent", { currentGuideComponent : guideComponentName });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
