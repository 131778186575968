<template>
  <div>
    <div class="csContsFea bg03_slaspeed">
      <p><b>품질보증(SLA) 기준에 만족하는지 확인 가능한 테스트로 <br />KT 인터넷 고객이 설치한 장소의 속도 측정에만 적용 가능합니다.</b></p>
      <p class="inverse">※ WiFi 무선인터넷 연결을 통한 속도측정은 품질 보증 대상이 아니므로, <br />유선 인터넷 연결을 통하여 측정해 주시기 바랍니다.</p>
      <div class="btnbox">
        <a href="#ifArea" class="redbtn btntolayer" @click="goTest($event, 0);">품질보증(SLA) 테스트</a>
      </div>
    </div>
    
    <!-- <a id="measureBtn" class="speed_speedtest_prestart_btn" href="#" @click.self.prevent="onSlaTestResult">sla result</a> -->
    <div class="csContsDetail" id="speedtestguide">
      <!-- <div class="iframediv layerfmbtn" id="ifArea" style="display:none; height: 900px;"> -->
      <div class="iframediv layerfmbtn" id="ifArea" :style="{ 'height' : (showCompProductArea || showCompSLATestArea)? '900px' : '0px' } ">
        <!-- <iframe id="ifAreaIframe" title="품질보증(SLA) 테스트 " scrolling="no" id="upfolder_iframe" name="upfolder_iframe" marginwidth="0" marginheight="0" leftmargin="0" topmargin="0" width="800" height="100%" frameborder="0"></iframe> -->
        <CompProductArea
          ref="CompProductArea"
          v-if="showCompProductArea"
          :parentPageName ="$options.name"
          :strDSPList="strDSPList"
        />
        <CompSLATestArea
          ref="CompSLATestArea"
          v-if="showCompSLATestArea"
          :selectedProductInfo ="selectedProductInfo"
          :insertSlaResultData ="insertSlaResultData"
          :parentPageName ="$options.name"
          :decryptedInfo="decryptedInfo"
          @onSlaTestResult ="onSlaTestResult"
        />
      </div>
      <dl class="csContsDetailDlist">
        <dt>측정안내</dt><!-- 0803 클래스 here 삭제, 링크 태그 삭제 -->
        <dd id="csContsDetailDefinition01">
          <h3>측정 방법 및 프로세스</h3>
          <ol>
            <li>1. SLA테스트를 실행 (총 5회 측정하며, 정확한 측정을 위해 품질측정 사이트 이 외 실행중인 프로그램을 종료)</li>
            <li>2. SLA테스트 결과 확인 시 SLA테스트 결과가 보증기준 미달(5회 측정가운데 3회 이상 기준에 미달)인 경우, 품질 점검을 위한 연락처 입력과 동시에 자동 이의신청 접수 진행</li>
            <li>3. KT A/S 직원이 방문하여 품질 점검 및 정밀 측정 실시</li>
            <li>4. 정밀측정 후 KT 관리 구간에서 SLA 품질미달이 확인 될 경우 규정에 따라 적정한 조치</li>
          </ol>
          <div class="imgdiv"><img src="../../assets/images/img01_slaspeedtestprocess.png" alt="1. 품질보증(SLA) 테스트 수행 2. 5회 측정 가운데 3회(60%) 이상 품질보증(SLA) 기준 적합여부측정 3. 다운로드 속도가 품질보증(SLA) 기준에 미달될 경우, 이의신청 가능 4.KT AS 직원이 인터넷 회선 설치장보를 방문, 정밀 품질 측정 실시 5. 정밀측정 결과 품질보증(SLA) 기준미달이 KT측 원인일 경우 규정에 따라 보상 등 적정한 조치"></div>
        </dd>
        <dt>※ 인터넷 상품별 최저보장속도</dt>
        <dd id="csContsDetailDefinition02">
          <ul class="bulletlist">
            <li> Lite : 2Mbps (FTTH : 25Mbps)</li>
            <li> 인터넷 슬림 : 2Mbps (FTTH/Ntopia : 50Mbps)</li>
            <li> 인터넷 슬림플러스 : 100Mbps</li>
            <li> 인터넷 베이직 : 250Mbps</li>
            <li> 인터넷 에센스 : 500Mbps</li>
            <li> 인터넷 프리미엄 : 1.25Gbps</li>
            <li> 인터넷 프리미엄플러스 : 2.5Gbps</li>
            <li> 인터넷 슈퍼프리미엄 : 5Gbps</li>
          </ul>
          <div class="bulletlistEx">
            <span>▶ 이외 상품의 경우, 상기 상품 중 최대 제공 속도가 동일한 상품을 기준으로 적용합니다.</span>
            <br>
            <span>ex) 인터넷 에센스 와이드의 경우, 인터넷 에센스 기준으로 최저보장속도 500Mbps</span>
            <br>
            <span>ex) 오피스넷 베이직의 경우, 인터넷 베이직 기준으로 최저보장속도 250Mbps</span>
          </div>
        </dd>
        <dt>측정방법</dt><!-- 0803 링크 태그 삭제 -->
        <dd id="csContsDetailDefinition02">
          <ul class="bulletlist">
            <li>SLA 측정구간은 고객 PC에서 KT인터넷 백본망에 위치한 측정서버까지 입니다.</li>
            <li>최저 속도 보장구간은 초고속 인터넷 서비스를 제공하는 ISP의 자사구간으로 한정됩니다.</li>
            <li>SLA 측정 시 다른 브라우저 창은 모두 종료하여야하며, 측정시 네트워크에 영향을 줄 수 있는 기타 모든프로그램은 종료하셔야 정확한 측정이 가능합니다.</li>
          </ul>
          <div class="imgdiv"><img src="../../assets/images/img01_speedtestprocess.png" alt="고객 댁내 인입망 영역에서는 인터넷 서비스 가입자, ONT, G(E)-PON(OLT)를 거치고, KT 인터넷망에서 KORNET 백본를 거쳐 품질측정 서비스망 영역은 WEB, DB 영역을 거쳐 측정서버들이 있는 측정서버 Farm을 포함합니다."></div>
        </dd>
        <dt>권장사양</dt><!-- 0803 링크 태그 삭제 -->
        <dd id="csContsDetailDefinition03">
          <table class="rowtable">
            <caption>권장사양</caption>
            <colgroup>
              <col width="28%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">권장사양</th>
                <td>
                  <ul class="bulletlist">
                    <li>운영체제 : 윈도우즈 10 이상 / 맥OS 지원</li>
                    <li>브라우저 : Edge/크롬/whale/파이어폭스
                      <p>* 윈도우10 하위버전 및 인터넷익스플로러는 마이크로소프트사의 지원중단 정책에 따라 사용중 오류가 발생할 수 있습니다.</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th scope="row">측정항목</th>
                <td>
                  <ul class="bulletlist">
                    <li>다운로드 속도(최대값, 최소값, 평균, 편차)</li>
                    <li>OS 정보, CPU / RAM 정보</li>
                    <li>브라우저 종류 및 버전정보
                      <p>*익스플로러는 호환성 보기를 해제하셔야 정확한 버전을 알 수 있습니다.</p>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </dd>
      </dl>
      <!-- 0717 알려드립니다 -> 유의사항 안내 박스 수정 -->
      <div class="csContsArticles">
        <div class="csbox">
          <h3>유의사항 안내</h3>
          <ul class="bulletlist">
            <li>SLA 테스트 전에 프로그램 설치가 필요합니다.</li>
            <li>SLA 테스트는 장기간 측정이 진행되므로, 일반 테스트 진행 후 SLA기준에 미달이 예상되는 회선만 측정 바랍니다.</li>
            <li>SLA 테스트는 KT인터넷 이용 고객만 측정이 가능합니다.</li>
            <li>SLA 테스트 시 인터넷 회선 속도 및 PC성능에 따라 측정 시간이 다를 수 있습니다.</li>
            <li>품질보증테스트의 경우 임시 회선ID (z! 로 시작하는 ID) 보유 고객은 ID 변경후 이용하시기 바랍니다.(문의:100번)</li>
            <li style="color : #d71826"><b>무선인터넷(WiFi, 공유기)으로 연결하여 속도 측정하는 경우는 품질 보증 대상이 아닙니다. 유선인터넷을 연결하여 속도 측정해 주시기 바랍니다.</b></li>
            <li>고객 정보보호를 위해 Safari 브라우저를 통한 서비스 이용이 제한되며, 다른 브라우저를 통해 이용이 가능합니다.</li>
            <li>해외 접속의 경우 서비스 이용이 제한됩니다.</li>
          </ul>
        </div>
      </div>
    </div>
    
    <PopupSLATestResultDetail   ref="PopupSLATestResultDetail"></PopupSLATestResultDetail>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'SLATestIntroduce',
  components: {
    CompProductArea  :()=>import('@/components/speed/CompProductArea'),
    CompSLATestArea:()=>import('@/components/sla/CompSLATestArea'),
    PopupSLATestResultDetail :()=>import('@/popup/sla/PopupSLATestResultDetail')
  },
  data() {
    return {
      kt_token : null
      , strDSPList : []
      , showifArea : false
      , showCompProductArea : false
      , showCompSLATestArea : false
      , showifAreaIframeProduct : false
      , selectedProductInfo : null
      , insertSlaResultData : {
          mSAID               : null,
          sla_index           : null,
          totuseqntevaplythrs : "N"
        }
    };
  },
  beforeRouteUpdate() {},
  beforeCreate() {},
  created() {
  

    this.setSLATestEventBus("$on");
    //this.$eventBus.$on("setCurrentPageName", this.setCurrentPageName);
    this.$eventBus.$emit("setCurrentPageName", { currentPageName : this.$options.name });
  },
  mounted() {},
  beforeUpdate(){},
  updated() {},
  beforeDestroy(){},
  destroyed(){
    this.setSLATestEventBus("$off");
  },
  methods: {
    async setSLATestEventBus( type ) {
      switch( type ) {
        case "$on" :
          this.$eventBus.$on("SLATestIntroduce/goProductTest", this.goProductTest);
          this.$nextTick(() => {
            this.pageInitDefault();
          });
          break;
        case "$off":
          this.$eventBus.$off("SLATestIntroduce/goProductTest", this.goProductTest);
          break;
        default : break;
      }
    },
    setProductList(list, type) {
      this.showCompSLATestArea = false;
      this.showifArea = (list.length > 0)? true : false;
      this.showCompProductArea = (type=="show" && !!list)? true : false;
      this.strDSPList = list;
      this.selectedProductInfo = null;
      this.insertSlaResultData =  { mSAID : null, sla_index : null, totuseqntevaplythrs : "N" };
    },
    /** --------------------------------------------------------
     * "품질보증(SLA) 테스트" 버튼.
     */
     async goTest(e, mtype){
      e.preventDefault();
      if ( window.kt.isLogin()=="N" ) {
        window.kt.popupLogin();
      } else {
        if (mtype==0) {
          this.pageEventEmit("goTest");
          //var list = await this.getOwnerAllStatusSubscpnTypeInfoRequest(this.$options.name);
          var list = await this.getOwnerSubscpnWithOfficeByPaging();
          this.setProductList(list, "show");
        }
      }
    },
    /**
     * SLA상품목록 하단 > 속도 테스트 버튼.
     */
    async goProductTest(selectedProductInfo) {
      this.setProductList([], "hide");
      this.selectedProductInfo = selectedProductInfo;
      this.showCompSLATestArea = true;
      // strSQLPrcInsSlaUserData // sla측정 사용자 정보 insert.
      var insertResult = await this.insertSlaUserData(selectedProductInfo);

      if (!!insertResult && !!insertResult.sla_index) {
        
        this.insertSlaResultData =  {
          mSAID               : insertResult.mSAID,
          sla_index           : insertResult.sla_index,
          totuseqntevaplythrs : insertResult.totuseqntevaplythrs
        };

        // ANTM연동 수정 230508 ,230605
        var ip = (!!insertResult && !!insertResult.ip)? insertResult.ip : "-1";
        var mac = (!!insertResult && !!insertResult.mac)? insertResult.mac : "-1";
        var params = {
          v_mac                     : mac,
          v_external_address        : ip,
          sla_index                 : this.insertSlaResultData.sla_index,
          v_scn                     : selectedProductInfo.subscpn_id
        }
        this.certificationUser(null, this.insertSlaResultData, params);
        // var antmResult = await this.certificationUser(null, this.insertSlaResultData, params);
        // // strSQLUpdSLAUserAnt
        // var updateParams = {
        //   resultcode : antmResult.resultcode,
        //   resultmsg  : antmResult.resultmsg,
        //   ftthflag   : antmResult.ftthflag,
        //   nescode    : antmResult.nescode,
        //   port       : antmResult.port,
        //   ontmac     : antmResult.ontmac,
        //   sla_index  : this.insertSlaResultData.sla_index
        // };
        // await this.updateSlaSpeedData(updateParams);
        // ANTM연동 수정 230508 ,230605

        // this.showCompSLATestArea = true;
      }
      // this.showCompSLATestArea = true;
    },
    onSlaTestResult() {
      // insertSlaResultData : {
      //   mSAID               : null,
      //   sla_index           : null,
      //   totuseqntevaplythrs : "N"
      // }
      var params = {
        // m_totUseqntEvAplyThrs
        searchParams : {
          mSAID               : this.insertSlaResultData.mSAID,
          sla_index           : this.insertSlaResultData.sla_index,
          totuseqntevaplythrs : this.insertSlaResultData.totuseqntevaplythrs
        },
      };
      //params.searchParams = {mSAID : "99990003343", sla_index: 504303, totuseqntevaplythrs: "N"};
      this.$refs.PopupSLATestResultDetail.popupVisible(params);
    },
  }

}
</script>

<style>
</style>
