/* eslint-disable */ 
var ktSpeedPlugin = {};
ktSpeedPlugin.install = function (Vue, options) {
  var service = {
    /** --------------------------------------------------------
     * kt multivalue cookie 일때 "=" delimiter 인데 값에 "=" 이 들어가는경우가 있다
     * split 특성상 특정 인덱스만 취했을겨우 저 값들이 버려질수 있음으로
     * 이를 처리하기위하여 만든 함수
     * 
     * asp에성 만든 function원본.
     * Function makeKTCookieMultivalueString(arr,strChar)
     *   
     *   Dim i
     *   Dim str
     *   str = arr(1)
     *   For i = 2 to UBound(arr)
     *     str = str & strChar	
     *   Next		
     *   makeKTCookieMultivalueString = str
     * End Function
     * @param {*} arr 
     * @param {*} strChar 
     * @returns 
     */
    async makeKTCookieMultivalueString(arr,strChar) {
      var str = arr[1];
      for(var i=2; i<arr.length; i++) {
        str += strChar;
      }
      return str;
    },
    /** --------------------------------------------------------
     * 조회 된 상품목록정보에 있는 주소 마스킹 처리.
     * @param {*} strAddress 
     * @param {*} count 
     * @returns 
     */
    async FormatMaskingAddressText(strAddress,count) {
      var retval  = "";
      var masking = false;
      if (!!strAddress) {
        var arrList = strAddress.split(" ");
        for(var item of arrList) {
          var tempItem = String(item);
          var chval = tempItem.slice(tempItem.length-1, tempItem.length);
          retval += (tempItem + " ");
          if (chval =="동" || chval =="리") {
            masking = true;
           break;
          }
        }
      }
      if ( masking ) {
        for(var index=0; index < count-1; index++) {
          retval += "*";
        }
      }
      return retval;
    },
    /** -------------------------------------------------------- //returnPageingSize(totalCount, pageBlockLimit, gridLimit, currentPage) {
     * returnPageingSize
     * @param {*} tableInfo 
     * @returns 
     */
    returnPageingSize(tableInfo) {
      var dataTotalCount = tableInfo.totalCount;
      var gridLimit           = tableInfo.gridLimit;
      var currentPage         = tableInfo.currentPage;
      var clickedButtonNumber = tableInfo.clickedButtonNumber;
      var pageBlockLimit      = tableInfo.pageBolockCount;
      
      var pageTotalCount  = Math.ceil(dataTotalCount / gridLimit);
      var currentPageGroup = Math.ceil(currentPage / pageBlockLimit);
      var pageTotalGroup = Math.ceil(pageTotalCount / pageBlockLimit);
      var start = ((currentPageGroup - 1) * pageBlockLimit) + 1;
      //var start = ((currentPageGroup-1)/pageBlockLimit) * pageBlockLimit + 1;
      //var end = (currentPageGroup==pageTotalCount)? start+1 : start + pageBlockLimit;
      //var end = (currentPageGroup==pageTotalCount)? start+1 : start + pageTotalCount;
      var end   = (start + pageBlockLimit - 1);
      var tempButtonList = [];

      // 페이징 버튼이 10개 이하 이면 1~페이징 버튼 수 만큼.
      if ( pageTotalCount <= pageBlockLimit ) {
        for (var i = start; i <= pageTotalCount; i++) {
          tempButtonList.push({ buttonNumber: i });
        }
      } else {
        // 페이징 버튼이 10개 초과.

        if ( clickedButtonNumber <= pageBlockLimit) {
          for (var i=start; i<=pageBlockLimit; i++) {
            tempButtonList.push({ buttonNumber: i });
          }
        } else {
          var tempStart = ((currentPage - 1) * gridLimit);
          //console.log(" start = " + start + " / end = " + end + " / tempStart = " + tempStart + " / gridLimit = " + gridLimit + " / dataTotalCount = " + dataTotalCount);
          if ( currentPageGroup == pageTotalGroup ) {
            //Math.ceil((61-50)/5)
            var beforePageTotalCount = (currentPageGroup-1)*gridLimit*pageBlockLimit;
            var tempEnd = Math.ceil((dataTotalCount-beforePageTotalCount)/gridLimit);
            for (var i=start; i<(start+tempEnd); i++) {
              tempButtonList.push({ buttonNumber: i });
            }
          } else {
            for (var i=start; i<=end; i++) {
              tempButtonList.push({ buttonNumber: i });
            }
          }
        }
      }
      return { buttonList : tempButtonList, pageTotalCount : pageTotalCount, currentPage : currentPage }
    },
    notNullValueReturn(valuename, str) {
      var returnValue = "";
      switch(valuename) {
        case "v_mac":
          returnValue = !!str? (str=="N/A"? "00:00:00:00:00:00" : str ) : "00:00:00:00:00:00";
          break;
        case "v_internal_address" :
          returnValue = !!str? str : "127.0.0.1";
          break;
        default :
          returnValue = !!str? str : "N/A";
          break;
      }
      return returnValue;
    },
  };
  Vue.prototype.$ktSpeedPlugin = service;
};

export default ktSpeedPlugin;