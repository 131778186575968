<template>
  <div style="box-sizing:border-box; width:100%;">
    <CompHistory
      ref="CompHistory"
      :parentComponentName="$options.name"
      :strDspList ="tableInfo.dataList"
      :strDspPage ="tableInfo.buttonList"
      :tableInfo  ="tableInfo"
    />
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'SpeedHistory',
  components: {
    CompHistory  :()=>import('@/components/common/CompHistory'),
  },
  data() {
    return {
      user_id : "",
      tableInfo: {
        gridLimit           : 5,
        offset              : 0,
        totalCount          : 0,
        dataList            : [],
        currentPage         : 1,
        clickedButtonNumber : 1,
        pageTotalCount      : 0,
        pageBolockCount     : 10,
        buttonList          : []
      }
    };
  },
  beforeRouteUpdate(a, b) {},
  beforeCreate() {},
  created() {
    this.setSpeedTestHistoryEventBus("$on");
    this.$eventBus.$emit("setCurrentPageName", { currentPageName : this.$options.name });
  },
  mounted() {},
  beforeUpdate(){},
  updated() {},
  beforeDestroy(){},
  destroyed(){
    this.setSpeedTestHistoryEventBus("$off");
  },
  methods: {
    setSpeedTestHistoryEventBus( type ) {
      switch( type ) {
        case "$on" :
          this.$eventBus.$on("SpeedHistory/getSpeedTestHistory", this.setSpeedTestHistoryInfo);
          this.$eventBus.$on("SpeedHistory/pageButtonClick", this.searchSpeedTestHistory);
          this.$nextTick(() => {
            this.pageInitDefault();
          });
          break;
        case "$off":
          this.$eventBus.$off("SpeedHistory/getSpeedTestHistory", this.setSpeedTestHistoryInfo);
          this.$eventBus.$off("SpeedHistory/pageButtonClick", this.searchSpeedTestHistory);
          break;
        default : break;
      }
    },
    setSpeedTestHistoryInfo(obj) {
      if (!!obj.decryptedInfo && obj.decryptedInfo.userid) {
        this.user_id = obj.decryptedInfo.userid;
        this.tableInfo = {
          gridLimit           : 5,
          offset              : 0,
          totalCount          : 0,
          dataList            : [],
          currentPage         : 1,
          clickedButtonNumber : 1,
          pageTotalCount      : 0,
          pageBolockCount     : 10,
          buttonList          : []
        };
        this.searchSpeedTestHistory(1);
      }
    },
    async searchSpeedTestHistory(pageNumber) {
      var start = ((pageNumber - 1) * this.tableInfo.gridLimit);
      var params = {
        user_id : this.user_id,
        limit  : 5,
        offset : start
      };
      var result = await this.$http.post(this.returnUrl("/speedtest/getSpeedTestHistory"), params).then(
        async (response)=>{
          return !!response.data? response.data : null;
      }).catch(
        async (error)=> {
          return null;
      });

      if ( !!result ) {
        this.tableInfo.totalCount = result.resultTotalCount;
        this.tableInfo.dataList = result.resultList;
        
        //var tempInfo = this.$ktSpeedPlugin.returnPageingSize(this.tableInfo.totalCount, this.tableInfo.pageBolockCount, this.tableInfo.gridLimit, this.tableInfo.currentPage)
        var tempInfo = this.$ktSpeedPlugin.returnPageingSize(this.tableInfo)
        this.tableInfo.currentPage    = tempInfo.currentPage;
        this.tableInfo.pageTotalCount = tempInfo.pageTotalCount;
        this.tableInfo.buttonList = tempInfo.buttonList;
      }
    },
  }

}
</script>

<style>
</style>
