<!-- 
   SPEED version 1.0
  
   Copyright ⓒ 2022 kt corp. All rights reserved.
   
   This is a proprietary software of kt corp, and you may not use this file except in 
   compliance with license agreement with kt corp. Any redistribution or use of this 
   software, with or without modification shall be strictly prohibited without prior written 
   approval of kt corp, and the copyright notice above does not evidence any actual or 
   intended publication of such software. 
 -->
<template>
  <div style="box-sizing:border-box; width:100%;">
    <CompSpeedTestGuide
      ref="CompSpeedTestGuide" v-if="currentGuideComponent=='CompSpeedTestGuide'"></CompSpeedTestGuide>

    <CompSLATestGuide
      ref="CompSLATestGuide" v-if="currentGuideComponent=='CompSLATestGuide'"></CompSLATestGuide>

    <CompInstallGuide
      ref="CompInstallGuide" v-if="currentGuideComponent=='CompInstallGuide'"></CompInstallGuide>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Guide',
  components: {
    CompSpeedTestGuide :()=>import('@/components/guide/CompSpeedTestGuide'),
    CompSLATestGuide   :()=>import('@/components/guide/CompSLATestGuide'),
    CompInstallGuide   :()=>import('@/components/guide/CompInstallGuide'),
  },
  data() {
    return {
      //currentGuidePage : "CompSpeedTestGuide"
    };
  },
  beforeRouteUpdate() {},
  beforeCreate() {},
  created() {
    this.$eventBus.$emit("setCurrentPageName", { currentPageName : this.$options.name, routeParams : this.$route.params });
  },
  mounted() {},
  beforeUpdate(){},
  updated() {},
  beforeDestroy(){},
  destroyed(){},
  methods: {
    changeGuideComponent(name) {
    }
  }

}
</script>

<style>
</style>
