<template>
  <div id="cfmOllehPopWrap" class="pop_dissent">
    <!-- custom-class="medium_dialog" -->
    <el-dialog
      title="속도측정 상세이력"
      custom-class="speedTestHistoryDetailPopup"
      center
      :visible.sync="dialogVisible"
      :before-close="cancel"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :modal="true"
      >
      <div>
        <a href="#" class="btn_close" @click="cancelBtn">
          <img src="../../assets/images/btn_close.jpg" alt="창 닫기" />
        </a>

        <h2><img src="../../assets/images/dis_h2_1.png" alt="측정일자" /></h2>

        <div class="test_table type_dis">
          <table class="bg_fb">
            <caption>측정일자</caption>
            <colgroup>
              <col width="160px"/>
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <!-- measureddate -->
                <td class="bg_typ_top" style="height:29px; border-bottom: 1px solid #e3e3e3; background-color: #fbfbfb;">{{detailInfo.measured_date}}</td>
                <td class="bg_typ_top" style="height:29px; border-bottom: 1px solid #e3e3e3; background-color: #fbfbfb;"></td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <h2><img src="../../assets/images/dis_h2_2.png" alt="고객 단말 정보" /></h2>
        <div class="test_table type1">
          <table>
            <caption>고객 단말 정보</caption>
            <colgroup>
              <col width="120px"/>
              <col width="*" />
              <col width="120px"/>
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <th class="bg_e8">운영체제</th>
                <td>{{detailInfo.os}}</td>
                <th class="bg_e8">브라우저</th>
                <td>{{detailInfo.browser}}</td>
              </tr>
              <tr>
                <th class="bg_f7">메모리</th>
                <td>{{ detailInfo.memory }} GB</td>
                <th class="bg_f7">CPU</th>
                <td>{{ detailInfo.processorname }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2><img src="../../assets/images/dis_h2_3.png" alt="Down / Up 속도정보" /></h2>
        <div class="test_table type2">
          <table>
            <caption>Down / Up 속도정보</caption>
            <colgroup>
              <col width="20%"/>
              <col width="20%"/>
              <col width="20%"/>
              <col width="20%"/>
              <col width="20%"/>
            </colgroup>
            <thead>
              <tr>
                <th class="bg_typ_top bg_de"></th>
                <th class="bg_typ_top">최대</th>
                <th class="bg_typ_top bg_f7">평균</th>
                <th class="bg_typ_top">최소</th>
                <th class="bg_typ_top bg_f7">편차</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="bg_f7">다운로드</th>
                <!-- dwmax -->
                <td>
                  {{detailInfo.down_speed_max_byte}} Mbps
                </td>
                <!-- dwavg -->
                <td class="bg_f7">
                  {{detailInfo.down_speed_avg_byte}} Mbps
                </td>
                <!-- dwmin -->
                <td>
                  {{detailInfo.down_speed_min_byte}} Mbps
                </td>
                <!-- dwsdv -->
                <td class="bg_f7">
                   {{detailInfo.down_speed_sdv_byte}} Mbps
                </td>
              </tr>
              <tr>
                <th class="bg_f7">업로드</th>
                <td>
                  {{detailInfo.up_speed_max_byte}} Mbps
                </td>
                <td class="bg_f7">
                  {{detailInfo.up_speed_avg_byte}} Mbps
                </td>
                <td>
                  {{detailInfo.up_speed_min_byte}} Mbps
                </td>
                <td class="bg_f7">
                  {{detailInfo.up_speed_sdv_byte}} Mbps
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2><img src="../../assets/images/dis_h2_5.png" alt="UDP 지연/손실" /></h2>
        <div class="test_table type2">
          <table>
            <caption>UDP 지연/손실</caption>
            <colgroup>
              <col width="20%"/>
              <col width="20%"/>
              <col width="20%"/>
              <col width="20%"/>
              <col width="20%"/>
            </colgroup>
            <thead>
              <tr>
                <th class="bg_typ_top bg_de"></th>
                <th class="bg_typ_top">최대</th>
                <th class="bg_typ_top bg_f7">평균</th>
                <th class="bg_typ_top">최소</th>
                <th class="bg_typ_top bg_f7">편차</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="bg_f7">지연시간</th>
                <td>
                  {{detailInfo.ping_max_rtt}} ms
                </td>
                <td class="bg_f7">
                  {{detailInfo.ping_avg_rtt}} ms
                </td>
                <td>
                  {{detailInfo.ping_min_rtt}} ms
                </td>
                <td class="bg_f7">
                  {{detailInfo.ping_sdv_rtt}} ms
                </td>
              </tr>
              <tr>
                <th class="bg_f7">손실률</th>
                <td>{{detailInfo.ping_loss_count}} %</td>
                <td class="bg_f7"></td>
                <td></td>
                <td class="bg_f7"></td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2><img src="../../assets/images/dis_h2_6.png" alt="경로추적 결과" /></h2>
        <div class="test_table type3">
          <table>
            <caption>경로추적 결과</caption>
            <colgroup>
              <col width="85px" />
              <col width="253" />
              <col width="79"/>
            </colgroup>
            <thead>
              <tr>
                <th class="bg_typ_top">HOP</th>
                <th class="bg_typ_top">HOST(IP Address)</th>
                <th class="bg_typ_top">지연시간</th>
              </tr>
            </thead>
            <tbody>
              <!-- strDSPTracert -->
              <tr v-for="(item, index) in strDSPTracert" :key="'strDSPTracert_'+index+'_'+item.seq">
                <td>{{item.seq}}</td>
                <td>{{item.hostMasking}}</td>
                <td>{{item.delay}} ms</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </el-dialog>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'PopupSpeedTestHistoryDetail',
  props: {},
  components: {},
  data () {
    return {
      dialogVisible: false,
      searchParams: {},
      detailInfo: {},
      strDSPTracert : [],
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    cancel() {
      this.detailInfo = {};
      this.dialogVisible = false;
    },
    cancelBtn(e) {
      e.preventDefault();
      this.cancel();
    },
    async popupVisible(params) {
      this.searchParams = !!params && params.searchParams? params.searchParams : null;
      await this.getSpeedTestHistoryDetailInfo();
    },
    /**
     * 속도테스트 이력 상세 조회.
     */
    async getSpeedTestHistoryDetailInfo() {
      var searchParams = !!this.searchParams ? this.searchParams : null;
      var data = null;

      if (!!this.searchParams) {
        var params = {
          qindex : this.searchParams.qindex
        };
        data = await this.$http.get(this.returnUrl("/speedtest/getSpeedTestHistoryDetailInfo"), {params : params}).then(
          async (response)=>{
            var status = !!response.status? response.status : null;
            var data = !!response.data ? response.data : null;
            return data;
        }).catch(async (error) => { 
          return null;
        });
      }
      if (!!data) {
        this.detailInfo = data.result;
        this.detailInfo.memory = Number(data.result.memory / 1024 / 1024).toFixed(2);
        this.detailInfo.down_speed_max_byte = Number(data.result.down_speed_max_byte * 8 / 1000000).toFixed(2);
        this.detailInfo.down_speed_avg_byte = Number(data.result.down_speed_avg_byte * 8 / 1000000).toFixed(2);
        this.detailInfo.down_speed_min_byte = Number(data.result.down_speed_min_byte * 8 / 1000000).toFixed(2);
        this.detailInfo.down_speed_sdv_byte = Number(data.result.down_speed_sdv_byte * 8 / 1000000).toFixed(2);
        
        this.detailInfo.up_speed_max_byte = Number(data.result.up_speed_max_byte * 8 / 1000000).toFixed(2);
        this.detailInfo.up_speed_avg_byte = Number(data.result.up_speed_avg_byte * 8 / 1000000).toFixed(2);
        this.detailInfo.up_speed_min_byte = Number(data.result.up_speed_min_byte * 8 / 1000000).toFixed(2);
        this.detailInfo.up_speed_sdv_byte = Number(data.result.up_speed_sdv_byte * 8 / 1000000).toFixed(2);

        this.detailInfo.ping_max_rtt = Number(data.result.ping_max_rtt).toFixed(2);
        this.detailInfo.ping_avg_rtt = Number(data.result.ping_avg_rtt).toFixed(2);
        this.detailInfo.ping_min_rtt = Number(data.result.ping_min_rtt).toFixed(2);
        this.detailInfo.ping_sdv_rtt = Number(data.result.ping_sdv_rtt).toFixed(2);

        this.detailInfo.ping_loss_count = Number((data.result.ping_loss_count / data.result.ping_count) * 100).toFixed(2);
        
        this.detailInfo.internal_address = data.result.internal_address;
        this.detailInfo.external_address = data.result.external_address;
        this.detailInfo.target_server_ip = data.result.target_server_ip;

        // var temp = "1:192.168.0.1:2|2:*:0|3:10.204.38.53:3|4:10.171.0.1:4|5:1.208.57.73:3|6:1.208.115.69:5|7:1.213.151.178:6|8:128.134.40.113:6|9:*:0|10:112.174.40.30:6|11:175.207.127.146:7|12:175.207.127.230:7|";
        // var arrtracertList = temp.split("|");  //data.result.result.split("|");
        var arrtracertList = data.result.result.split("|");
        var strDSPTracert = []; 

        // Masking Start
        for (var item of arrtracertList){
          if ( !!item ) {
            var arrtracertItem = item.split(":");
            var seq            = arrtracertItem[0];
            // var hostItemList   = arrtracertItem[1].split(".");
            var hostMasking   = arrtracertItem[1];
            var delay          = Number(arrtracertItem[2]).toFixed(2);

            // var hostMasking = "";
            // if (hostItemList.length >= 3) {
            //   hostMasking = "***" + "." + hostItemList[1] + "." + "***" + "." + hostItemList[3];
            // } else {
            //   hostMasking = hostItemList[0];
            // }

            // if ( delay < 0 ) {
            //   delay = "*";
            // }

            strDSPTracert.push({ seq : seq, hostMasking : hostMasking, delay : delay });
          }
        }
        // Masking End
        
        this.strDSPTracert = strDSPTracert;
        this.dialogVisible = true;
      } else {
        this.cancel();
      }
      
    }
  }
}
</script>
<style scoped>
</style>