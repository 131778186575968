const getters = {
  url               : state => state.app.url,
  scriptUrl         : state => state.app.scriptUrl,
  systemOption      : state => state.systemOption.systemOption,
  measurementOption : state => state.systemOption.measurementOption,
  speedAgentDebug   : state => state.systemOption.speedAgentDebug,
  speedAgentTrace   : state => state.systemOption.speedAgentTrace,
  version           : state => state.systemOption.version,
  windowsVersion    : state => state.systemOption.windowsVersion,
  macVersion        : state => state.systemOption.macVersion,
};
export default getters;