/* eslint-disable */
import Vue    from "vue";
import Router from "vue-router";
import SpeedTestIntroduce          from '@/page/speed/SpeedTestIntroduce';
import SpeedHistory                from '@/page/speed/SpeedHistory';
import SLATestIntroduce            from '@/page/sla/SLATestIntroduce';
import SLAHistory                  from '@/page/sla/SLAHistory';
import SLAComplain                 from '@/page/sla/SLAComplain';
import Guide                       from '@/page/guide/Guide';
import PopupSpeedTestResultDetail  from '@/popup/speed/PopupSpeedTestResultDetail';
import PopupSpeedTestHistoryDetail from '@/popup/speed/PopupSpeedTestHistoryDetail';
import PopupSLATestResultDetail    from '@/popup/sla/PopupSLATestResultDetail';

Vue.use(Router);

const requireAuthCheck = () => async (to, from, next) => {
  return next();
};

// speed/speedtest/introduce.asp
export default new Router({
  mode : "history",
  routes : [
    { path : "/",
      //redirect : "/speed/speedtest/introduce.asp",
      redirect : to =>{
        //return "/speed/speedtest/introduce.asp";
        window.location.href = "/speed/speedtest/introduce.asp";
      },
      beforeEnter: requireAuthCheck(),
    },
    { path : "/speed/speedtest/introduce.asp",
      name : "SpeedTestIntroduce",
      component : SpeedTestIntroduce,
      beforeEnter: requireAuthCheck(),
    },
    { path : "/speed/popup/speedTestResultDetail",
      name : "speedTestResultDetail",
      component : PopupSpeedTestResultDetail,
      beforeEnter: requireAuthCheck(),
    },
    { path : "/speed/history/history.asp",
      name : "SpeedHistory",
      component : SpeedHistory,
      beforeEnter: requireAuthCheck(),
    },
    { path : "/speed/popup/speedTesthistoryDetail",
      name : "speedTesthistoryDetail",
      component : PopupSpeedTestHistoryDetail,
      beforeEnter: requireAuthCheck(),
    },
    { path : "/sla/slatest/introduce.asp",
      name : "SLATestIntroduce",
      component : SLATestIntroduce,
      beforeEnter: requireAuthCheck(),
    },
    { path : "/sla/popup/slaTestResultDetail",
      name : "slaTestResultDetail",
      component : PopupSLATestResultDetail,
      beforeEnter: requireAuthCheck(),
    },
    { path : "/sla/history/history.asp",
      name : "SLAHistory",
      component : SLAHistory,
      beforeEnter: requireAuthCheck(),
    },
    { path : "/sla/history/comphistory.asp",
      name : "SLAComplain",
      component : SLAComplain,
      beforeEnter: requireAuthCheck(),
    },
    { path : "/guide/guide.asp",
      name : "Guide",
      component : Guide,
      beforeEnter: requireAuthCheck(),
    },
  ]
});
