/* eslint-disable */
import Vue from 'vue';
import App from './App.vue';
import VureRouter from "vue-router";
import router from "./router";
import Element from 'element-ui';
import LocaleKO from 'element-ui/lib/locale/lang/ko';
import 'element-ui/lib/theme-chalk/index.css'; //import를 안하면 elemenu ui style 적용 안됨.

import axios from "axios";
import store from "./store";
import {EventBus} from "./store/eventBus";

import commonPlugin from '@/plugins/commonPlugin';
import commonMixin from "./mixins/commonMixin";

import VueCookies from 'vue-cookies';
import VueSession from 'vue-session';
import browserDetect from "vue-browser-detect-plugin";

Vue.config.productionTip = false;

Vue.prototype.$http = axios;

Vue.prototype.$eventBus = EventBus;
Vue.use(Element, { locale: LocaleKO });
Vue.use(VureRouter);
Vue.use(commonPlugin);
Vue.use(VueSession);
Vue.use(VueCookies);
Vue.use(browserDetect);
Vue.mixin(commonMixin);

store.dispatch("setScriptUrl", { "NODE_ENV": process.env.NODE_ENV });

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
