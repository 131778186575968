/* eslint-disable */
import systemOption from "./systemOption";

const operUrlInfo = {
  COM_KT_WWW    : "www.kt.com",
  COM_KT_HELP   : "help.kt.com",
  DRECTORY_NAME : "speed",
  CXDUSPEEDKEY  : "13ccc8c6-5ab7-42ab-9e07-74aa9822e17f",
  CXDUSLAKEY    : "812d14d7-f5dc-4c6d-a313-4b91963729ae",
  CSATURL       : "https://dt.kt.co.kr"
};

const testUrlInfo = {
  COM_KT_WWW    : "tb.kt.com",
  COM_KT_HELP   : "dev.help.kt.com",
  DRECTORY_NAME : "tbspeed",
  CXDUSPEEDKEY  : "32f18dbf-8813-4007-851a-8e2ac1ccf22b",
  CXDUSLAKEY    : "2d76b66c-9232-4632-9b80-397bb35e7d95",
  CSATURL       : "http://dev.dt.kt.co.kr"
};


let tempScriptUrlInfo = {};
const state = {
  url : {
  // WASURL : (process.env.NODE_ENV === "development")? "http://localhost:8087/ws" : "/ws",
    WASURL : (process.env.NODE_ENV === "development")? "http://test.speed.kt.com/ws" : "/ws",
  },
  scriptUrl : {}
};

const mutations = {
  ScriptUrl: (state, obj) => {
    if ( process.env.NODE_ENV==="production" && !!systemOption && systemOption.buildType==="OP" ) {
      tempScriptUrlInfo = operUrlInfo; //배포 시 주석 해제 필요.
    } else {
      // process.env.NODE_ENV === "development"
      tempScriptUrlInfo = testUrlInfo;
    }
    state.scriptUrl = tempScriptUrlInfo;
  },
  getCookies: async (state, obj) => {
    var tempCookieInfo = {
      kt_sso_encid: "",
      kt_sso_credtid: "",
      kt_sso_userid: "",
      kt_sso_activationid: "",
      kt_sso_token: "",
    };
    return await tempCookieInfo.kt_sso_token;
  }
};
const actions = {
  setScriptUrl({ commit },value) {
    commit('ScriptUrl', value);
  }
};


export default {
  state,
  mutations,
  actions
};