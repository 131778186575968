<template>
  <div style="box-sizing:border-box; width:100%;">
    <CompHistory
      ref="CompHistory"
      :parentComponentName="$options.name"
      :strDspList ="tableInfo.dataList"
      :strDspPage ="tableInfo.buttonList"
      :tableInfo  ="tableInfo"
    />
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'SLAHistory',
  components: {
    CompHistory  :()=>import('@/components/common/CompHistory'),
  },
  data() {
    return {
      user_id : "",
      tableInfo: {
        gridLimit           : 5,
        offset              : 0,
        totalCount          : 0,
        dataList            : [],
        currentPage         : 1,
        clickedButtonNumber : 1,
        pageTotalCount      : 0,
        pageBolockCount     : 10,
        buttonList          : []
      }
    };
  },
  beforeRouteUpdate(a, b) {},
  beforeCreate() {},
  created() {
    this.setSLATestHistoryEventBus("$on");
    this.$eventBus.$emit("setCurrentPageName", { currentPageName : this.$options.name });
  },
  mounted() {},
  beforeUpdate(){},
  updated() {},
  beforeDestroy(){},
  destroyed(){
    this.setSLATestHistoryEventBus("$off");
  },
  methods: {
    setSLATestHistoryEventBus( type ) {
      switch( type ) {
        case "$on" :
          this.$eventBus.$on("SLAHistory/getSLATestHistory", this.setSLATestHistoryInfo);
          this.$eventBus.$on("SLAHistory/pageButtonClick", this.searchSLATestHistory);
          this.$nextTick(() => {
            this.pageInitDefault();
          });
          break;
        case "$off":
          this.$eventBus.$off("SLAHistory/getSLATestHistory", this.setSLATestHistoryInfo);
          this.$eventBus.$off("SLAHistory/pageButtonClick", this.searchSLATestHistory);
          break;
        default : break;
      }
    },
    setSLATestHistoryInfo(obj) {
      if (!!obj.decryptedInfo && obj.decryptedInfo.userid) {
        this.user_id = obj.decryptedInfo.userid;
        this.tableInfo = {
          gridLimit           : this.$store.getters.systemOption.DefaultPageRowSize,
          offset              : 0,
          totalCount          : 0,
          dataList            : [],
          currentPage         : 1,
          clickedButtonNumber : 1,
          pageTotalCount      : 0,
          pageBolockCount     : 10,
          buttonList          : []
        };
        this.searchSLATestHistory(1);
      }
    },
    /**
     * SLA테스트 이력 목록 조회.
     * @param {*} pageNumber 
     */
    async searchSLATestHistory(pageNumber) {
      var start = ((pageNumber - 1) * this.$store.getters.systemOption.DefaultPageRowSize);
      var params = {
        user_id : this.user_id,
        limit   : this.$store.getters.systemOption.DefaultPageRowSize,
        offset  : start,
        DefaultSLAThresHold : this.$store.getters.measurementOption.DefaultSLAThresHold
      };
      var result = await this.$http.get(this.returnUrl("/speedtest/getSLATestHistory"), {params : params}).then(
        async (response)=>{
          return !!response.data? response.data : null;
      }).catch(
        async (error)=> {
          return null;
      });

      if ( !!result ) {
        var IsSLARate = this.$store.getters.measurementOption.DefaultSLARate;
        var IsSLACnt  = this.$store.getters.measurementOption.DefaultSLACount;
        for(var item of result.resultList) {
          var mcnt    = item.tcnt;
          var mslacnt = item.slacnt;
          //mslarate	= FormatNumber(CDbl(Cint(mslacnt) / Cint(mcnt))*100,2)
          var mslarate	= Number((Number(mslacnt) / Number(mcnt))*100).toFixed(2)
          if (Number(mcnt) < IsSLACnt) {
            item.isok = "측정횟수부족";
          } else {
            if ( mslarate >= IsSLARate) {
              item.isok = "미달";
            } else {
              item.isok = "만족";
            }
          }
        }
        
        this.tableInfo.totalCount = result.resultTotalCount;
        this.tableInfo.dataList = result.resultList;
        
        //var tempInfo = this.$ktSpeedPlugin.returnPageingSize(this.tableInfo.totalCount, this.tableInfo.pageBolockCount, this.tableInfo.gridLimit, this.tableInfo.currentPage)
        var tempInfo = this.$ktSpeedPlugin.returnPageingSize(this.tableInfo)
        this.tableInfo.currentPage    = tempInfo.currentPage;
        this.tableInfo.pageTotalCount = tempInfo.pageTotalCount;
        this.tableInfo.buttonList     = tempInfo.buttonList;
      }
    },
  }

}
</script>

<style>
</style>
